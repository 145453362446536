@import 'master';

slideshow {
  position: relative;
  display: block;
  @include flex-center-center();

  img {
    @include absolute(top 0 left 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .dots {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 2rem;
    @include flex-center-center();

    .dot {
      border-radius: 50%;
      background-color: lightgray;
      opacity: 0.85;
      width: 12px;
      height: 12px;
      margin: 0 0.5rem;
      box-sizing: content-box;
      background-clip: content-box;
      border: 3px solid transparent;

      &.selected {
        background-color: white;
        box-shadow: 0 0 0 0.5px white;
      }
    }
  }
}

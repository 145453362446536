@import 'master';

$controlWidth: 45px;

scale-control {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid var(--background5);
  width: $controlWidth;

  & > * {
    width: 100%;
    height: $controlWidth;
    @include flex-center-center();

    &:first-child {
      border-bottom: 1px solid var(--background5);
    }
  }
  #scale-display {
    font-size: small;
    border-bottom: 1px solid var(--background5);
  }

  .actionon {
    background-color: var(--global-active-background-alpha-high);
    color: var(--global-active-foreground-alpha-high);
  }

  .actionoff {
    background-color: var(--background6-alpha-high);
    color: var(--foreground6);
  }

  button {
    background-color: var(--background6-alpha-high);
    &.disabled {
      icon svg {
        fill: var(--foreground6);
      }
    }

    icon {
      width: 50%;
      height: 50%;

      svg {
        fill: var(--foreground6);
      }
    }
  }
}

expanding-quick-menu {
  .menu-content {
    display: flex;
    flex-direction: column;
    margin-top: 0.25rem;
    overflow: hidden;

    & > * + * {
      margin-top: 0.25rem;
    }
  }

  .shrink-icon icon {
    padding: 0.25rem;
  }
}

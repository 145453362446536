@import 'master';

floorplan-card {
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .hidden-svg-loader {
    position: absolute;
    opacity: 0;
  }

  .accordion-header {
    icon.is-inventory-home {
      svg {
        fill: var(--inventory-home-color) !important;
      }
    }

    favorite-btn {
      margin-left: auto;
      margin-right: 1rem;
      display: block;
    }
  }

  .accordion-container {
    &.no-collapse {
      border-radius: var(--border-radius-cards);
    }

    .body-content {
      font-size: 14px;
      padding: 0;

      .neighborhood-label {
        @include flex-center-center();
        font-size: 16px;
        background-color: var(--background1);
        color: var(--foreground1);
        padding: 0.4rem;

        icon {
          max-width: 1.5rem;
          max-height: 1.5rem;
          margin-right: 0.5rem;
          svg {
            fill: var(--foreground1-alpha-medium);
          }
        }
      }

      .card-content {
        padding: 0.5rem 1rem;

        .navigation-buttons {
          display: flex;
          padding: 0.5rem 0;
          align-items: center;
          justify-content: space-between;

          lot-selector {
            width: 140px;
            margin-right: 0.5rem;
          }

          .view-static-gallery-button {
            margin-left: auto;
            margin-right: 10px;
            button {
              background-color: var(--standardbuttonforeground);
              border: none;
              outline: none;
              box-shadow: none;

              icon {
                width: 1.5rem;
                height: 1.5rem;

                svg {
                  fill: var(--standardbuttonbackground);
                }
              }
            }
          }

          .external-media-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-left: auto;
            margin-right: 10px;
            svg {
              fill: var(--standardbuttonbackground);
            }

            &:hover {
              cursor: pointer;
            }
          }

          .view-floorplan-button.noMediaImages {
            margin-left: auto;
          }
        }

        gallery {
          margin: 0.5rem 0;
          height: 30vh;
        }
      }
    }
  }
}

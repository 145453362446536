masonry-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 33.3%));
  grid-auto-rows: 5px;
  grid-row-gap: 0.7rem;
  justify-content: start;
  #spacing-placeholder {
    height: 50px;
    grid-column: 1;
  }
}

@media screen and (max-width: 1300px) {
  masonry-layout {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

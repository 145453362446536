accordion-sections-content {
  p,
  ul li {
    white-space: pre-wrap;
  }

  & > * + * {
    margin-top: 1.5rem;
  }

  accordion .accordion-container {
    .accordion-header {
      div[header] > icon {
        width: 30px;
        height: 30px;
        margin-right: 0.5rem;
      }

      favorite-btn {
        margin-right: 0.5rem;
      }
    }

    .accordion-body {
      .body-content {
        padding: 2rem;
      }

      div[body] > * + *:not(accordion) {
        margin-top: 0.5rem;
      }
    }
  }

  // for nested accordion styles
  accordion .accordion-container accordion {
    padding-top: 1rem;
    &:last-child .accordion-container {
      border-bottom: 1px solid var(--background5);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .accordion-container {
      &:not(.open) {
        border-top: 1px solid var(--background5);
        border-radius: 0;
      }

      &.open {
        .accordion-header {
          background-color: var(--background1);
          color: var(--foreground1);

          .toggler icon svg {
            fill: var(--foreground1);
          }
        }
      }

      .accordion-header {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .body-content {
        padding: 1rem;
      }
    }
  }
}

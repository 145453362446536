@import 'master';

modal {
  .modal-backdrop {
    @include fixed(top 0 left 0 right 0 bottom 0);
    z-index: 6;
    @include flex-center-center();

    .modal {
      background-color: var(--modal-background-alpha-high);
      color: var(--modal-foreground);
      min-width: 500px;
      max-width: 66vw;
      padding: 58px 52px 54px 42px; // matches toast
      position: relative;
      border-radius: var(--border-radius-modals);
      max-height: 95vh;
      overflow-y: auto;

      @include media('<desktop') {
        min-width: unset;
        max-width: 90vw;
        padding: 38px 32px 34px 22px; // matches toast
      }

      @include media('<tablet') {
        max-width: 100vw;
      }

      .close-icon {
        @include absolute(top 0 right 0);
        width: 1rem;
        height: 1rem;
        margin: 1.5rem 1.25rem;
        cursor: pointer;

        svg {
          fill: var(--modal-foreground);
        }

        &:focus {
          outline: 2px solid black;
        }
        &:focus:not(:focus-visible) {
          outline: none;
        }
      }

      icon {
        svg {
          fill: var(--modal-icon-foreground-alpha-medium);
        }
      }

      icon-button {
        &.priority {
          button icon {
            svg {
              fill: var(--prioritybuttonforeground);
            }
          }
        }
        button icon {
          svg {
            fill: var(--standardbuttonforeground);
          }
        }
      }
    }
  }
}

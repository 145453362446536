@import 'master';

badge {
  display: inline-flex;
  align-items: center;
  height: 30px;

  icon {
    height: 100%;
    width: 1.7rem;
    background-color: var(--global-active-background);
    @include flex-center-center();
    position: relative;

    svg {
      width: 45%;
      height: 45%;
      fill: var(--global-active-foreground);
    }
  }
  .value {
    height: 100%;
    background-color: var(--background2);
    color: var(--foreground2);
    padding: 0 10px;
    @include flex-center-center();
  }
  &.disabled {
    opacity: 0.3;
  }
}

@import 'master';

floorplan-filters {
  display: block;

  header {
    toggle {
      padding-right: 0.5rem;
    }

    badge {
      margin-right: 1rem;
    }
  }

  .badges {
    padding: 1rem;
    border-top: 1px solid var(--foreground2-alpha-low);

    badge {
      margin: 3px 10px 3px 0;
    }
  }

  .accordion-container {
    .body-content {
      .header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .filter-header {
          color: var(--foreground6);
          font-size: 0.9em;
          text-transform: uppercase;
        }
      }

      .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .filter {
          width: 47%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding: 0.5rem 0;

          &.full-width {
            width: 100%;

            .value-display {
              width: 100%;
            }
          }

          .filter-header {
            height: 25px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            margin: 0.4rem 0 0.2rem 0;

            icon {
              height: 1rem;
              width: 1rem;
              margin-right: 0.5rem;

              &.active {
                svg {
                  fill: var(--global-active-background);
                }
              }

              &.inactive {
                svg {
                  fill: var(--foreground6);
                }
              }
            }
          }

          numeric-input {
            height: 2.5rem;
            margin-top: 5px;
          }
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          mat-select {
            margin-top: 5px;
          }
          &.disabled {
            .filter-header {
              color: var(--background5);
              icon svg {
                fill: var(--background5);
              }
            }
            label {
              color: var(--background5);
            }
          }
        }
      }
    }
  }
}

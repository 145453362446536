@import 'master';

numeric-input {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  & > * {
    height: 3rem;
    @include flex-center-center();
    margin: 0 3px;
    padding: 5px 3px;
  }

  .value-display {
    background-color: var(--background2);
    width: 4rem;
  }

  button {
    background-color: var(--global-active-background);
    width: 3rem;
    border-radius: var(--border-radius-numeric-input-buttons);

    &.disabled {
      cursor: default;
      background-color: var(--background5);

      icon svg {
        fill: var(--foreground5);
      }
    }

    icon {
      width: 50%;
      height: 50%;

      svg {
        fill: var(--global-active-foreground);
      }
    }
  }
}

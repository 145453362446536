@import 'master';

floorplan-features {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  .features-container {
    width: 100%;
    background-color: var(--background6);
    color: var(--foreground6);
    box-shadow: var(--box-shadow-inputs);
    border-radius: var(--border-radius-cards);
    position: relative;

    .features-header {
      width: 100%;
      padding: 0.5rem 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      h2 {
        flex-grow: 1;
        font-size: 16px;
      }

      .arrow {
        width: 14px;
        height: 14px;
        margin-left: auto;

        icon {
          transform: rotate(0);
          transition: transform 150ms ease-in;

          svg {
            fill: var(--foreground6);
          }
        }
      }
    }

    &.open {
      & > .features-header {
        .arrow {
          icon {
            transform: rotate(90deg);
          }
        }
      }
      & > .features-body {
        transition: visibility 200ms, opacity 200ms ease-in;
        visibility: visible;
        opacity: 1;
        border-top: 1px dotted var(--background5);
      }
    }
    .features-body {
      position: absolute;
      z-index: 3;
      background-color: var(--background6);
      color: var(--foreground6);
      overflow-x: visible;
      @include hoverVisibilityScrollbarOverflowY;
      box-shadow: var(--box-shadow-inputs);
      border-radius: var(--border-radius-cards);
      width: 100%;
      max-height: 25vh;

      opacity: 0;
      transition: visibility 200ms, opacity 200ms ease-in;
      visibility: hidden;
    }
    .features-content {
      width: 100%;
      padding: 0.5rem 1rem;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    .features-list-item {
      display: flex;
      padding: 0.5rem 0;
      .text {
        flex: 1 1 auto;

        h3 {
          font-size: 14px;
        }
        p {
          font-size: 11px;
        }
      }
    }
  }
}

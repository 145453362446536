@import 'master';

floating-page-title {
  @include absolute(top 0 left 0);
  z-index: 2;
  width: 100%;
  min-height: var(--floating-page-title-height);
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: var(--background1-alpha-high);
  color: var(--foreground1);
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-family: var(--headerfont);
  text-transform: var(--headerfontuppercase);

  icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    flex-shrink: 0;

    svg {
      fill: var(--foreground1);
    }
  }
}

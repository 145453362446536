@import 'master';

lot-selector {
  display: flex;
  align-items: center;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  mat-select {
    flex: 1 1 auto;
    border-radius: var(--border-radius-buttons);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-option-custom {
  icon {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 1rem;

    svg {
      fill: var(--foreground6);
    }
  }

  .is-inventory-home {
    svg {
      fill: var(--inventory-home-color);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { roundToPlace } from '@ml/common';
import { ThemeService } from '../services/theme.service';

@Pipe({
  name: 'milesToKilometers',
  standalone: true
})
export class MilesToKilometersPipe implements PipeTransform {
  constructor(private themeService: ThemeService) {}

  transform(miles: number): number {
    if (this.themeService.distanceUnits === DistanceUnits.Kilometers) {
      return roundToPlace(miles * 1.60934, 1);
    } else {
      return miles;
    }
  }
}

export enum DistanceUnits {
  Miles = 'Miles',
  Kilometers = 'Kilometers'
}

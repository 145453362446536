@import 'master';

image-with-title {
  h3 {
    margin-bottom: 1rem;
  }

  img {
    object-fit: contain;
    object-position: center;

    max-height: 45vh;
    max-width: calc((100vw - var(--current-slide-out-menu-width)) / 2);
  }
}

@import 'master';

floating-side-menu {
  @include absolute(top 0 right 0 bottom 0);
  z-index: 6;
  width: 100vw;
  height: 100%;
  pointer-events: none;

  &.open {
    .backdrop {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }

    .menu {
      transform: translateX(0);
    }
  }

  .backdrop {
    @include absolute(left 0);
    width: 67vw;
    height: 100%;
    background-color: var(--transparent-gray);
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-in-out;
  }

  .menu {
    @include absolute(right 0);
    width: 33vw;
    height: 100%;
    background-color: var(--background2-alpha-high);
    color: var(--foreground2);
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
    pointer-events: auto;
    display: flex;
    flex-direction: column;

    & > header {
      border-bottom: 1px solid var(--foreground2-alpha-medium);
      flex: 0 0 auto;
      z-index: 2;
      display: flex;

      &.scrolled-shadow {
        box-shadow: var(--box-shadow);
      }

      div[header] {
        flex: 1 1 auto;
        overflow: hidden;

        icon {
          margin-right: 0.5rem;
        }
      }

      icon {
        width: calc(var(--floating-page-title-height) / 2.5);
        height: calc(var(--floating-page-title-height) / 2.5);
        flex-shrink: 0;

        svg {
          fill: var(--foreground2);
        }
      }

      button {
        width: var(--floating-page-title-height);
        min-height: var(--floating-page-title-height);
        height: 100%;
        margin-left: auto;
        @include flex-center-center();
        border-left: 1px solid var(--foreground2-alpha-medium);
        flex: 0 0 auto;
      }
    }

    & > .body {
      flex: 1 1 auto;
      padding: 1rem 0;
      @include hoverVisibilityScrollbarOverflowY;
    }
  }

  @include media('<desktop') {
    .backdrop {
      width: 50vw;
    }
    .menu {
      width: 50vw;
    }
  }

    @include media('<tablet', 'portrait') {
    .backdrop {
      width: 25vw;
    }
    .menu {
      width: 75vw;
    }
  }
}

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';

import { ErrorReasons } from '../../error/error-reasons.enum';
import { AppModeTypes } from '../helpers/app-mode.enum';
import { CommunityService } from './community.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private currentPageSubject = new BehaviorSubject<INavigationOptions>(null);
  private currentBackButtonSubject = new Subject<PageTitle>();
  private currentAppMode = AppModeTypes.FullScp;
  private routeLookup = new Map<string, string>();

  public IsInStandaloneMode = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communityService: CommunityService,
    private location: Location
  ) {}

  get current$(): Observable<INavigationOptions> {
    return this.currentPageSubject.asObservable();
  }

  get backButton$(): Observable<PageTitle> {
    return this.currentBackButtonSubject.asObservable();
  }

  get AppMode(): AppModeTypes {
    return this.currentAppMode;
  }

  detectApplicationMode(route?: ActivatedRouteSnapshot, url?: string) {
    if (!route) route = this.route.snapshot;
    if (!url) url = this.router.url;

    const currentPage = this.getNavigationOptionsFromUrl(url);
    this.currentPageSubject.next(currentPage);

    if (url.includes('/home-configurator/')) {
      this.currentAppMode = AppModeTypes.HomeConfigurator;
    } else {
      this.IsInStandaloneMode = route.queryParamMap.get('standalone')?.toLowerCase() === 'true';

      if (this.IsInStandaloneMode) {
        if (currentPage.PageTitle === PageTitle.CommunityMap) {
          this.currentAppMode = AppModeTypes.StandaloneISM;
        } else if (currentPage.PageTitle === PageTitle.AreaMap) {
          this.currentAppMode = AppModeTypes.StandaloneAreaMap;
        } else if (currentPage.PageTitle === PageTitle.Visualizer) {
          this.currentAppMode = AppModeTypes.StandaloneVisualizer;
        }
      } else if (
        currentPage.PageTitle === PageTitle.Brochure &&
        currentPage.RouteParams?.FavoritesSessionId
      ) {
        this.currentAppMode = AppModeTypes.BrochureShare;
      }
    }
  }

  get AllowAppNavigation(): boolean {
    return !this.IsInStandaloneMode && this.AppMode !== AppModeTypes.BrochureShare;
  }

  isAlreadyOnPage(pageTitle: PageTitle) {
    return this.currentPageSubject.value.PageTitle === pageTitle;
  }

  private getPageRoute(
    pageTitle: PageTitle,
    options?: IRouteParams,
    queryParams?: IRouteQueryParams
  ): string {
    if (!options) options = {};

    let route = (
      this.AppMode === AppModeTypes.HomeConfigurator
        ? AppRoutePrefixes.HomeConfigurator
        : AppRoutePrefixes.MyScp
    ) as string;

    switch (pageTitle) {
      case PageTitle.Homepage:
        route += PageRoute.Homepage;
        break;
      case PageTitle.AreaMap:
        route += PageRoute.AreaMap;
        break;
      case PageTitle.CommunityMap:
        let routePart = '';
        ({ routePart, options, queryParams } = this.determineSiteMapRoute(options, queryParams));
        route += routePart;
        break;
      case PageTitle.FloorPlans:
        route += options.FloorplanId ? PageRoute.FloorPlan : PageRoute.FloorPlans;
        break;
      case PageTitle.Error:
        route = PageRoute.Error;
        break;
      case PageTitle.Brochure:
        route += options.FavoritesSessionId ? PageRoute.SharedBrochure : PageRoute.Brochure;
        break;
      case PageTitle.CustomPage:
        route += PageRoute.CustomPage;
        break;
      case PageTitle.EnergyEfficiency:
        route += PageRoute.EnergyEfficiency;
        break;
      case PageTitle.Visualizer:
        route += PageRoute.Visualizer;
        break;
      case PageTitle.Interior:
        route += PageRoute.Interior;
        break;
      case PageTitle.Exterior:
        route += PageRoute.Exterior;
        break;
      case PageTitle.Summary:
        route += PageRoute.Summary;
        break;
      default:
        queryParams = { reason: ErrorReasons.RouteNotFound, route: pageTitle };
        route = PageRoute.Error;
    }

    if (!options.CommunityId && route !== PageRoute.Error)
      options.CommunityId = this.communityService.current.CommunityId;

    route = this.setRouteParams(options, route);
    route = this.setRouteQueryParams(queryParams, route);

    return route;
  }

  private setRouteQueryParams(queryParams: IRouteQueryParams, route: string) {
    if (!queryParams) return route;

    route +=
      '?' +
      Object.keys(queryParams)
        .filter(param => !!queryParams[param])
        .map(param => `${param}=${queryParams[param]}`)
        .join('&');
    return route;
  }

  private setRouteParams(options: IRouteParams, route: string) {
    // replace route placeholders with any passed in option values
    Object.keys(options).forEach(key => {
      const regex = new RegExp('{' + key + '}', 'i');
      route = route.replace(regex, options[key]);
    });
    return route;
  }

  getNavigationOptionsFromUrl(url: string): INavigationOptions {
    const [path, queryParamsString]: string[] = url.split('?');

    let normalizedPath = path.replace(/\/\d+/gi, '/');
    Object.values(AppRoutePrefixes).forEach(prefix => {
      normalizedPath = normalizedPath.replace(prefix, '');
    });

    // remove all numeric ids from the current url and match it with existing routes
    const route = this.getRouteLookup().get(normalizedPath);

    const routeIds = path.match(/\d+/gi);
    const routeParams: IRouteParams = { ...(routeIds && { CommunityId: +routeIds[0] }) };

    let queryParams = this.getRouteQueryParams(queryParamsString);

    let pageTitle: PageTitle = PageTitle.Error;
    switch (PageRoute[route]) {
      case PageRoute.Homepage:
        pageTitle = PageTitle.Homepage;
        break;
      case PageRoute.AreaMap:
        pageTitle = PageTitle.AreaMap;
        break;
      case PageRoute.CommunityMap:
        pageTitle = PageTitle.CommunityMap;
        break;
      case PageRoute.NeighborhoodMap:
        pageTitle = PageTitle.CommunityMap;
        routeParams.NeighborhoodId = +routeIds[1];
        break;
      case PageRoute.AmenityMap:
        pageTitle = PageTitle.CommunityMap;
        routeParams.AmenityMapId = +routeIds[1];
        break;
      case PageRoute.FloorPlans:
        pageTitle = PageTitle.FloorPlans;
        break;
      case PageRoute.FloorPlan:
        pageTitle = PageTitle.FloorPlans;
        routeParams.FloorplanId = +routeIds[1];
        break;
      case PageRoute.Error:
        pageTitle = PageTitle.Error;
        break;
      case PageRoute.Brochure:
        pageTitle = PageTitle.Brochure;
        break;
      case PageRoute.SharedBrochure:
        pageTitle = PageTitle.Brochure;
        routeParams.FavoritesSessionId = +routeIds[1];
        break;
      case PageRoute.CustomPage:
        pageTitle = PageTitle.CustomPage;
        routeParams.PageId = +routeIds[1];
        break;
      case PageRoute.EnergyEfficiency:
        pageTitle = PageTitle.EnergyEfficiency;
        routeParams.PageId = +routeIds[1];
        break;
      case PageRoute.Visualizer:
        pageTitle = PageTitle.Visualizer;
        break;
      case PageRoute.Interior:
        pageTitle = PageTitle.Interior;
        break;
      case PageRoute.Exterior:
        pageTitle = PageTitle.Exterior;
        break;
      case PageRoute.Summary:
        pageTitle = PageTitle.Summary;
        break;
      default:
        queryParams = { reason: ErrorReasons.RouteNotFound.toString(), route: path };
        pageTitle = PageTitle.Error;
    }

    return {
      PageTitle: pageTitle,
      RouteParams: routeParams,
      ...(queryParams && { QueryParams: queryParams })
    };
  }

  private getRouteQueryParams(queryParamsString: string): IRouteQueryParams {
    const urlSearchParams = new URLSearchParams(queryParamsString);
    if (!![...urlSearchParams].length) {
      const queryParams: Params = {};
      urlSearchParams.forEach((val: string, key: string) => {
        queryParams[key] = val;
      });
      return queryParams;
    }
  }

  getRouteLookup() {
    if (!!this.routeLookup.size) return this.routeLookup;
    Object.entries(PageRoute).forEach(([routeName, routeUrl]) => {
      // find "{somethingId}" placeholders in the routes and remove them
      this.routeLookup.set(routeUrl.replace(/{[a-z]+Id}/gi, ''), routeName);
    });
    return this.routeLookup;
  }

  go(data: INavigationOptions) {
    this.router
      .navigateByUrl(this.getPageRoute(data.PageTitle, data.RouteParams, data.QueryParams))
      .then(success => {
        if (success) this.currentPageSubject.next(data);
      });
  }

  handleErrorRedirect(errorReason: ErrorReasons) {
    // GIFLENS-https://media0.giphy.com/media/Rej54wKP8pGNjtYMzY/200.gif
    // use this to ensure route goes into history -- makes it easier to hit back and retry page load
    this.location.go(this.location.path());

    this.go({ PageTitle: PageTitle.Error, RouteParams: {}, QueryParams: { reason: errorReason } });
    return EMPTY;
  }

  private determineSiteMapRoute(
    options: IRouteParams,
    queryParams: IRouteQueryParams
  ): { routePart: string; options: IRouteParams; queryParams: IRouteQueryParams } {
    let routePart = PageRoute.CommunityMap;
    const newOptions = { ...options };
    const newQueryParams = { ...queryParams };

    if (options.AmenityMapId) {
      routePart = PageRoute.AmenityMap;
    } else if (options.NeighborhoodId) {
      routePart = PageRoute.NeighborhoodMap;
    }

    if (this.AppMode === AppModeTypes.StandaloneISM) newQueryParams.standalone = 'true';

    return { routePart, options: newOptions, queryParams: newQueryParams };
  }

  getSharedBrochureLink(favoritesSessionId: number) {
    return `${window.location.origin}${this.getPageRoute(PageTitle.Brochure, {
      FavoritesSessionId: favoritesSessionId
    })}`;
  }

  getSharedHomeConfigurationLink(sessionId: number, buildId: string) {
    const routeWithParams = this.getPageRoute(PageTitle.Summary, null, {
      sessionId,
      buildId
    });
    return `${window.location.origin}${routeWithParams}`;
  }

  setBackButtonPage(pageTitleToGoBackTo: PageTitle) {
    this.currentBackButtonSubject.next(pageTitleToGoBackTo);
  }

  clearBackButtonPage() {
    this.currentBackButtonSubject.next(null);
  }
}

export interface IRouteParams {
  CommunityId?: number;
  NeighborhoodId?: number;
  FloorplanId?: number;
  AmenityMapId?: number;
  PageId?: number;
  FavoritesSessionId?: number;
}

export interface IRouteQueryParams extends Params {
  [key: string]: any;
}

export enum PageTitle {
  // Both MyScp and HomeConfigurator
  CommunityMap = 'Community Map',
  FloorPlans = 'Floor Plans',
  // MyScp specific
  Homepage = 'Home',
  AreaMap = 'Area Map',
  Brochure = 'Brochure',
  Error = 'Error',
  CustomPage = 'Custom Page',
  EnergyEfficiency = 'Energy Efficiency',
  Visualizer = 'Visualizer',
  // HomeConfigurator specific
  Interior = 'Interior',
  Exterior = 'Exterior',
  Summary = 'Summary'
}

enum AppRoutePrefixes {
  MyScp = '/scp',
  HomeConfigurator = '/home-configurator'
}

export enum PageRoute {
  Homepage = '/{communityId}/home',
  AreaMap = '/{communityId}/area-map',
  FloorPlans = '/{communityId}/floorplan/list',
  FloorPlan = '/{communityId}/floorplan/{floorplanId}',
  CommunityMap = '/{communityId}/site-map/community-map',
  NeighborhoodMap = '/{communityId}/site-map/{neighborhoodId}',
  AmenityMap = '/{communityId}/site-map/amenity-map/{amenityMapId}',
  Error = '/error',
  Brochure = '/{communityId}/brochure/current',
  SharedBrochure = '/{communityId}/brochure/{favoritesSessionId}',
  CustomPage = '/{communityId}/custom-page/{pageId}',
  EnergyEfficiency = '/{communityId}/energy-efficiency/{pageId}',
  Visualizer = '/{communityId}/visualizer',
  Interior = '/{communityId}/interior',
  Exterior = '/{communityId}/exterior',
  Summary = '/{communityId}/final-summary'
}

export interface INavigationOptions {
  PageTitle: PageTitle;
  RouteParams?: IRouteParams;
  QueryParams?: IRouteQueryParams;
}

@import 'master';

slide-out-menu .menu {
  @include absolute(top 0 right 0);
  z-index: 3;
  background-color: var(--background2-alpha-high);
  height: 100%;
  width: var(--slide-out-menu-standard-width);
  transition: transform 300ms ease; // matches some pages main content width transition timing
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0 12px 3px rgba(102, 101, 101, 0.3);

  @include media('<desktop', 'portrait') {
    --slide-out-menu-standard-width: 75vw;
  }

  @include media('<desktop', 'landscape') {
    --slide-out-menu-standard-width: 50vw;
  }

  &.open {
    transform: translateX(0) !important;

    .pull-tab {
      icon {
        transform: scale(-1, 1);
        opacity: 0.7;
      }
    }
  }

  &.left-origin {
    left: 0;
    right: auto;
    transform: translateX(-100%);

    &.open {
      .pull-tab {
        icon {
          transform: scale(1);
          opacity: 0.7;
        }
      }
    }

    .pull-tab-and-hint-container {
      left: var(--slide-out-menu-standard-width);
      right: auto;
      flex-direction: row-reverse;

      .hint-container {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
    .pull-tab {
      border-radius: 0 var(--border-radius-buttons) var(--border-radius-buttons) 0;

      icon {
        transform: scale(-1, 1);
        opacity: 1;
      }
    }
  }

  & > header {
    background-color: var(--background1);
    color: var(--foreground1);
    font-size: 26px;
    padding: 20px;
    flex: 0 0 auto;

    .heading {
      display: flex;
      align-items: flex-start;
      font-size: 22px;
      font-family: var(--headerfont);
      text-transform: var(--headerfontuppercase);
      margin-bottom: 1em;

      .heading-icon {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        padding: 3px;

        svg {
          fill: var(--icon-foreground);
        }
      }
      .icon-background {
        background-color: var(--icon-background);
      }

      .title {
        flex: 1 1 auto;
        line-height: 1.2;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 0;

    & > div[body-fixed] {
      box-shadow: var(--box-shadow);
      position: relative;
      &.scrolled-shadow {
        box-shadow: var(--box-shadow);
        position: relative;
        z-index: 2;
      }
    }

    & > div[body] {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      @include hoverVisibilityScrollbarOverflowY;

      gallery-accordion {
        display: block;

        gallery {
          height: 250px;
        }
      }

      accordion:first-child {
        section {
          margin-top: 0;
        }
      }
    }

    .body-tabs {
      display: flex;

      .body-tab {
        padding: 0.85rem;
        flex: 1 1 auto;
        text-align: center;
        background-color: #bdbdbd9e; // leave hardcoded -- meant to be a "shadow" over whatever color
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        // margin-bottom: 1rem;
        color: var(--foreground2);

        &.active {
          background-color: var(--background2-alpha-high);
          // box-shadow: 0 -5px 6px 0px #cfcfcfcc;
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }

    .tab-content {
      display: none;

      &.show {
        display: block;
      }

      & > .header {
        display: inline-flex;
        align-items: baseline;
        padding: 1rem 0;

        .header-label {
          font-size: 16px;
          font-weight: bold;
        }

        .counter {
          margin-left: 1rem;
        }
      }
    }
  }

  .pull-tab-and-hint-container {
    position: absolute;
    top: 10px;
    right: var(--slide-out-menu-standard-width);
    display: flex;

    &.bottom {
      bottom: 10px;
      top: auto;
    }
  }

  .hint-container {
    display: flex;
    align-items: center;
    color: var(--foreground1);
    font-size: 11px;
    font-weight: bold;
    width: 200px;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    background-color: var(--background1);
    opacity: 0.6;
    border-radius: var(--border-radius-buttons);

    icon {
      width: 45px;
      height: 45px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      svg {
        fill: var(--foreground1);
      }
    }
  }

  .pull-tab {
    background-color: var(--background1-alpha-high);
    padding: 1rem 0.3rem;
    border-radius: var(--border-radius-buttons) 0 0 var(--border-radius-buttons);
    @include flex-center-center();

    icon {
      width: 20px;
      height: 20px;
      transition: opacity 2ms ease-in;
      svg {
        fill: var(--foreground1);
      }
    }
  }
}

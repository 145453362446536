toggle .toggle {
  width: 3em;

  .toggle-btn {
    display: inline-block;
    width: 3em;
    height: 1.5em;
    position: relative;
    cursor: pointer;
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid var(--background5-alpha-medium);

    &:focus::after,
    &:active::after {
      box-shadow: 0 0 0 0.75px rgba(0, 0, 0, 0.1), 0 2px 0 rgba(0, 0, 0, 0.08),
        inset 0px 0px 0px 1.5px var(--background5);
    }
    &::after {
      transform: translate3d(0, 0, 0);
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      border-radius: 2em;
      background: var(--background5);
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease,
        margin 0.3s ease;
      box-shadow: 0 0 0 0.75px rgba(0, 0, 0, 0.1), 0 2px 0 rgba(0, 0, 0, 0.08);
    }
    &.toggle-btn-on::after {
      transform: translate3d(100%, 0, 0);
      background-color: var(--global-active-foreground);
    }
    &.toggle-btn-on {
      background-color: var(--customToggleOnFill, var(--global-active-background));
    }
    &.toggle-btn-on:active {
      box-shadow: none;
    }
    &.toggle-btn-on:active::after {
      margin-left: -0.8em;
    }
    &:active::after {
      padding-right: 0.8em;
    }
  }

  .toggle-input {
    display: none;
  }
}

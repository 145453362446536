import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';

import { ColorLuminanceModifiers, ColorTransparencyModifiers } from '../helpers/configs';
import { FavoriteType } from '../helpers/favorite-types.enum';
import { pluralizeString } from '../helpers/helpers';
import { SettingsService } from './settings.service';

import { ISetting, SettingFieldType } from '@ml/common';
import { overrideLibraryIcon } from '@ml/web-components';
import { DistanceUnits } from '../pipes/miles-to-kilometers.pipe';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private customFonts = ['Gotham', 'Gotham Narrow', 'Proxima Nova', 'Charter ITC'];

  constructor(private settingsService: SettingsService) {}

  lotLabel: string;
  fpLabel: string;
  sqftLabel: string;
  neighborhoodLabel: string;
  inventoryHomeLabel: string;
  inventoryHomeAbbreviation: string;
  floorplanOptionsLabel: string;
  compareMenuLabel: string;
  distanceUnits = DistanceUnits.Miles;

  initializeFromSettings() {
    this.setAndLoadFonts();
    this.swapInIcons();
    this.applySettingsToCssVariables();
    this.setBackdropBlurLevel();
    this.addCustomCssFile();

    this.lotLabel = this.getLotLabel();
    this.fpLabel = this.getFloorPlanLabel();
    this.sqftLabel = this.getSqftLabel();
    this.neighborhoodLabel = this.getNeighborhoodLabel();
    this.inventoryHomeLabel = this.getInventoryHomeLabel();
    this.inventoryHomeAbbreviation = this.getInventoryHomeAbbreviation();
    this.floorplanOptionsLabel = this.getFloorplanOptionsLabel();
    this.compareMenuLabel = this.getCompareMenuLabel();

    if (this.settingsService.get('DistanceUnits') === DistanceUnits.Kilometers) {
      this.distanceUnits = DistanceUnits.Kilometers;
    }
  }

  addCustomCssFile() {
    const customCssSetting: string = this.settingsService.get('CustomThemeCss');
    switch (customCssSetting) {
      case 'Toll':
        this.addLinkStylesheetToHead('/styles/custom-themes/toll.css');
        break;
      case 'Century':
        this.addLinkStylesheetToHead('/styles/custom-themes/century.css');
        break;
      case 'Ashton':
        this.addLinkStylesheetToHead('/styles/custom-themes/ashton.css');
        break;
    }
  }

  getCommunityLogo(): string {
    return this.settingsService.get('CommunityLogo');
  }

  getClientLogo(): string {
    return this.settingsService.get('ClientLogo');
  }

  getIconNameFromFavoriteType(favoriteType: FavoriteType) {
    switch (favoriteType) {
      case FavoriteType.Floorplan:
        return 'floorplan';
      case FavoriteType.InventoryHome:
        return 'inventory_home';
      case FavoriteType.Lot:
        return 'site_map';
      case FavoriteType.PointOfInterest:
        return 'marker';
      case FavoriteType.VisualizerSelection:
        return 'cube';
      case FavoriteType.Elevation:
      case FavoriteType.Media:
      case FavoriteType.AmenityMedia:
        return '';
    }
  }

  getInventoryHomeLabel(plural: boolean = false): string {
    const inventoryHomeLabel = this.settingsService.get('InventoryHomeLabel');
    return plural ? pluralizeString(inventoryHomeLabel) : inventoryHomeLabel;
  }

  getInventoryHomeAbbreviation(plural: boolean = false): string {
    const inventoryHomeAbbreviation = this.settingsService.get('InventoryHomeAbbreviationLabel');
    return plural ? pluralizeString(inventoryHomeAbbreviation) : inventoryHomeAbbreviation;
  }

  getFloorPlanLabel(plural: boolean = false): string {
    const floorPlanLabel = this.settingsService.get('FloorPlanLabel') || 'Floor Plan';
    return plural ? pluralizeString(floorPlanLabel) : floorPlanLabel;
  }

  getSqftLabel(): string {
    return this.settingsService.get('SpecSqFtLabel') || 'Sq. Ft.';
  }

  getLotLabel(plural: boolean = false): string {
    const lotLabel = this.settingsService.get('LotLabel');
    return plural ? pluralizeString(lotLabel) : lotLabel;
  }

  getNeighborhoodLabel(plural: boolean = false): string {
    const neighborhoodLabel = this.settingsService.get('NeighborhoodLabel');
    return plural ? pluralizeString(neighborhoodLabel) : neighborhoodLabel;
  }

  getVisualizerSelectionLabel(plural: boolean = false): string {
    const visualizerSelectionLabel =
      this.settingsService.get('VisualizerFavoritesLabel') || 'Visualizer Selection';
    return plural ? pluralizeString(visualizerSelectionLabel) : visualizerSelectionLabel;
  }

  getFloorplanOptionsLabel(): string {
    const optionsLabel = this.settingsService.get('FloorplanOptionsLabel');
    return optionsLabel || 'Options';
  }

  getCompareMenuLabel(): string {
    const compareLabel = this.settingsService.get('CompareMenuLabel');
    return compareLabel || 'Compare';
  }

  setAndLoadFonts() {
    const relevantSettings = this.settingsService.getAllForFonts();
    const root = document.documentElement;
    relevantSettings.forEach(setting => {
      const familyName = setting.Value;
      root.style.setProperty(this.getSafeVariableName(setting.UIName), `'${familyName}'`);
      this.addLinkStylesheetToHead(this.getCssLink(familyName, setting));
      this.setFontCasing(setting.Name);
      this.setFontWeight(setting.Name);
    });
  }

  private getCssLink(familyName: string, setting: ISetting) {
    if (this.customFonts.includes(familyName)) {
      const linkSafeName = familyName.replace(/ /g, '');
      return `/assets/fonts/${linkSafeName}.css`;
    } else {
      const linkSafeName = familyName.replace(/ /g, '+');
      const weight = this.getGoogleFontWeightString(setting.Name);
      return `https://fonts.googleapis.com/css2?family=${linkSafeName}${weight}&display=swap`;
    }
  }

  private getGoogleFontWeightString(settingName: string): string {
    // For now, load these weights for primary font and use weight setting for header font
    if (settingName !== 'HeaderFont') return ':wght@100;400;700';
    const weight = FontWeight[this.settingsService.get(`${settingName}Weight`) || 'Regular'];
    return `:wght@${weight}`;
  }

  private addLinkStylesheetToHead(href: string) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    // NOTE: appended to body so ensure it has precedence to override standard styles
    document.body.appendChild(link);
  }

  private setFontWeight(fontSettingName: string) {
    // Header font weight is set via google font url
    if (fontSettingName === 'HeaderFont') return '';

    // Accordion header uses the primary font but has it's own font weight setting
    // and primary font doesn't have a font weight setting...
    // GIFLENS-https://media1.giphy.com/media/4JVTF9zR9BicshFAb7/200.gif
    if (fontSettingName === 'PrimaryFont') fontSettingName = 'AccordionHeaderFont';

    const fontWeightSettingName = `${fontSettingName}Weight`;
    const weight = FontWeight[this.settingsService.get(fontWeightSettingName) || 'Regular'];

    const propName = this.getSafeVariableName(fontWeightSettingName);
    document.documentElement.style.setProperty(propName, weight);
  }

  private setFontCasing(fontSettingName: string) {
    if (fontSettingName !== 'HeaderFont') return '';
    const casingSettingName = `${fontSettingName}Uppercase`;
    const isUppercase = !!this.settingsService.get(casingSettingName);

    if (!isUppercase) return;
    const propName = this.getSafeVariableName(casingSettingName);
    document.documentElement.style.setProperty(propName, 'uppercase');
  }

  swapInIcons() {
    const relevantSettings = this.settingsService.getAllForIcons();
    relevantSettings
      .filter(x => !!x.UIValue)
      .forEach(setting => {
        overrideLibraryIcon(setting.UIName, setting.UIValue);
      });
  }

  applySettingsToCssVariables() {
    const relevantSettings = this.settingsService.getAllForCssVariables();
    const root = document.documentElement;
    relevantSettings.forEach(setting => {
      root.style.setProperty(this.getSafeVariableName(setting.UIName), setting.Value);

      if (setting.FieldType === SettingFieldType.ColorPicker) {
        const color = new TinyColor(setting.Value);
        ColorTransparencyModifiers.forEach(modifier => {
          root.style.setProperty(
            this.getSafeVariableName(setting.UIName + modifier.Suffix),
            color.setAlpha(modifier.Opacity).toRgbString()
          );
        });

        // At this time only needed for background colors
        if (setting.Name.toLowerCase().includes('background')) {
          ColorLuminanceModifiers.forEach(modifier => {
            root.style.setProperty(
              this.getSafeVariableName(setting.UIName + modifier.Suffix),
              color.lighten(modifier.PercentLighter).setAlpha(1).toRgbString()
            );
          });
        }
      }
    });
  }

  setBackdropBlurLevel() {
    const blurLevel = BackdropBlurLevel[this.settingsService.get('ToastAndModalBackgroundBlur')];

    const root = document.documentElement;
    const blurLevelPropertyName = this.getSafeVariableName('blur-level');
    root.style.setProperty(blurLevelPropertyName, `${blurLevel}px`);
  }

  getDisclaimerText(type: FavoriteType) {
    switch (type) {
      case FavoriteType.Floorplan:
        return this.settingsService
          .get('IfpDisclaimerText')
          ?.replace('{{currentYear}}', new Date().getFullYear());
      case FavoriteType.Elevation:
        return this.settingsService
          .get('ExteriorDisclaimerText')
          ?.replace('{{currentYear}}', new Date().getFullYear());
      case FavoriteType.VisualizerSelection:
        return this.settingsService
          .get('VisualizerDisclaimerText')
          ?.replace('{{currentYear}}', new Date().getFullYear());
      case FavoriteType.PointOfInterest:
        return this.settingsService
          .get('PoiDisclaimerText')
          ?.replace('{{currentYear}}', new Date().getFullYear());
      case FavoriteType.Lot:
      case FavoriteType.LotMedia:
        return this.settingsService
          .get('LotDisclaimerText')
          ?.replace('{{currentYear}}', new Date().getFullYear());
      case FavoriteType.PageContentSection:
        return this.settingsService
          .get('CustomTemplateItemDisclaimerText')
          ?.replace('{{currentYear}}', new Date().getFullYear());
      default:
        return '';
    }
  }

  private getSafeVariableName(name: string): string {
    let varName = name.toLowerCase();
    varName = varName.startsWith('--') ? varName : `--${varName}`;
    return varName;
  }

  updateSlideOutMenuCurrentWidth(isOpen: boolean) {
    const root = document.documentElement;
    const standardWidthPropertyName = this.getSafeVariableName('slide-out-menu-standard-width');
    const currentWidthPropertyName = this.getSafeVariableName('current-slide-out-menu-width');

    const newWidth = isOpen ? root.style.getPropertyValue(standardWidthPropertyName) : '0px';
    root.style.setProperty(currentWidthPropertyName, newWidth);
  }

  updateCssVariable(variableName: string, variableValue: string) {
    const propName = this.getSafeVariableName(variableName);
    document.documentElement.style.setProperty(propName, variableValue);
  }

  getBottomNavHeight() {
    return Number(
      getComputedStyle(document.documentElement, null)
        .getPropertyValue('--bottom-nav-height')
        .trim()
        .replace('px', '')
    );
  }

  updateBottomNavHeight() {
    const parsedNavHeight = this.getBottomNavHeight();
    this.updateCssVariable('--bottom-nav-height', `${parsedNavHeight + 20}px`);
  }
}

enum BackdropBlurLevel {
  Light = 1,
  Medium = 5,
  Heavy = 10
}

enum FontWeight {
  Light = 100,
  Regular = 400,
  Bold = 700
}

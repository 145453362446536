@import '../../../styles/master';

.hc-help-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 0;
  background-color: transparent;
  border-radius: 8px;
  max-width: 60vw;

  @include media('<tablet') {
    max-width: 80vw;
  }
}

hc-help-message-dialog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--hc-general-modal-background);
  color: var(--hc-general-modal-foreground);
  padding: 3rem;
  border-radius: 8px;

  .message {
    margin-right: 2rem;

    p {
      font-size: 16px;

      &.small {
        font-size: 12px;
      }
    }

    * + * {
      margin-top: 1rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;

    * + * {
      margin-top: 1rem;
    }
  }

  .selectable {
    color: var(--hc-general-modal-foreground);
    text-decoration: underline;
    display: inline-block;
    font-size: 12px;
    white-space: nowrap;
  }

  @include media('<tablet') {
    flex-direction: column;

    .message {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
}

@import 'master';

gallery {
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;

  @include flex-center-center();

  .loader {
    @include absolute(top 0 right 0 bottom 0 left 0);
    z-index: 2;
    background-color: var(--background2-alpha-medium);
    @include flex-center-center();
  }

  header {
    @include absolute(top 0);
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    &.has-title {
      background-color: var(--gallery-ctrl-bkg);
      color: var(--foreground1);
    }

    &.has-inventory-home-label {
      background-color: var(--inventory-home-color-alpha-medium);
      color: var(--inventory-home-foreground);
    }

    .inventory-home-label {
      width: 100%;
      text-align: center;
    }

    favorite-btn {
      margin-left: auto;
    }
    &.top-header-controls {
      height: 75px;
      z-index: 3;
      width: 50%;
      padding-left: 2rem;
      background-color: initial;
      left: 0;
    }
  }

  img,
  .img-placeholder,
  .html-container {
    background-color: var(--ifpbackground);
    @include animation-fadeIn();
  }

  .img-placeholder {
    height: 222px;
  }

  iframe,
  video,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: center;

    &.fit-contain {
      object-fit: contain;
    }
  }

  .swipe-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }

  .swipe-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    background-color: lightgray;

    &.is-animating {
      transition: transform 200ms cubic-bezier(0.5, 0, 0.5, 1);
    }
  }

  .image-slide {
    display: inline-block;
    width: 100%;
    height: 100%;

    .html-container,
    .transform-receptor,
    .svg-container,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .gallery-controls {
    @include flex-center-center();
    /*@include absolute(bottom 0);*/
    z-index: 2;
    width: 100%;
    background-color: var(--gallery-ctrl-bkg);
    padding: 0.25rem;

    &.top-controls {
      top: 0;
      bottom: unset;
      justify-content: flex-end;
      padding-right: 1rem;
      height: 75px;
      icon-button {
        icon {
          height: 1.5em;
          width: 1.5em;
        }
      }
      > .pager {
        margin: 0 2rem;
      }
    }

    .left-arrow,
    .right-arrow,
    .fullscreen-btn {
      button {
        background-color: transparent;
        box-shadow: none;
        border: none;
      }
    }
  }

  .pager {
    margin: 0 0.5rem;
    z-index: 3;
    @include flex-center-center();
    flex-wrap: wrap;

    .page-number {
      flex-shrink: 0;
      color: var(--foreground1);
    }
  }

  .left-arrow,
  .right-arrow,
  .fullscreen-btn {
    @include flex-center-center();

    &.left-arrow {
      transform: rotate(180deg);
    }

    button {
      svg {
        fill: var(--gallery-button-foreground);
      }
    }

    &.top-screen {
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid var(--foreground1);
    }
  }

  .btn-background {
    & > button {
      background-color: var(--gallery-button-bkg);
      box-shadow: var(--boxshadowbuttons);
    }
  }

  .button-left {
    @include absolute(left 0.25rem bottom 0.25rem);
    z-index: 3;
  }

  .button-right {
    @include absolute(right 0.25rem bottom 0.25rem);
    z-index: 3;
  }
}

@import 'master';

icon-button {
  display: block;

  &.small {
    font-size: 14px;
  }

  &.medium {
    font-size: 16px;
  }

  &.large {
    font-size: 18px;
  }

  &.is-on-background1 button {
    border: 1px solid var(--foreground1-alpha-medium);
  }

  &.priority button {
    background-color: var(--prioritybuttonbackground);
    color: var(--prioritybuttonforeground);

    icon svg {
      fill: var(--prioritybuttonforeground);
    }

    &:active:not([disabled]) {
      background-color: var(--prioritybuttonbackground-alpha-high);
    }
  }

  &.is-inventory-home button {
    background-color: var(--inventory-home-color);
    color: var(--inventory-home-foreground);

    icon svg {
      fill: var(--foreground4);
    }

    &:active:not([disabled]) {
      background-color: var(--background4-alpha-high);
    }
  }

  &.transparent button {
    background-color: var(--standardbuttonbackground-alpha-medium);
    border: 1px solid var(--standardbuttonbordercolor-alpha-medium);

    &:active:not([disabled]) {
      background-color: var(--standardbuttonbackground-alpha-low);
      border-color: var(--standardbuttonbordercolor-alpha-low);
    }
  }

  &.fill-width {
    button {
      width: 100%;
      justify-content: center;
    }
  }

  &.selected {
    button {
      background-color: var(--global-active-background);
      color: var(--global-active-foreground);

      icon svg {
        fill: var(--global-active-foreground);
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--standardbuttonbackground);
    color: var(--standardbuttonforeground);
    border-radius: var(--border-radius-buttons);
    box-shadow: var(--boxshadowbuttons);
    padding: 0.4em;
    border: 1px solid var(--standardbuttonbordercolor);

    &:active:not([disabled]) {
      background-color: var(--standardbuttonbackground-alpha-high);
      border-color: var(--standardbuttonbordercolor-alpha-high);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.has-text {
      padding: 0.6rem 1rem;

      .optional-icon {
        margin-right: 0.5em;
      }
    }

    icon {
      width: 1em;
      height: 1em;
      margin: 0;
      flex-shrink: 0;

      &.arrow-icon {
        margin-left: 0.5em;
      }

      svg {
        fill: var(--standardbuttonforeground);
      }
    }

    .text {
      white-space: nowrap;
    }
  }

  @include media('<desktop') {
    button {
      &.has-text {
        font-size: 14px;
      }
    }
  }
}

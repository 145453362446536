.scrolling-gallery-modal {
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  width: 80vw;

  .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    overflow-y: hidden;
  }

  header {
    width: 100%;
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;

    h3 {
      text-align: center;
      font-size: 2rem;

      icon {
        width: 1.5rem;

        &.is-inventory-home {
          svg {
            fill: var(--inventory-home-color) !important;
          }
        }
      }
    }
    .close-modal-button button {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
      margin-left: 20px;

      &:active:not([disabled]) {
        background: none;
        border: none;
      }

      icon {
        width: 1.3rem;
        height: 1.3rem;

        svg {
          fill: var(--standardbuttonbackground);
        }
      }
    }
  }

  #scroll-gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 80vw;
    max-height: 85vh;
    overflow-y: auto;
    margin-top: 10px;
    padding-bottom: 20px;

    .scrolling-image-container {
      width: 65%;
      margin-top: 2rem;
      box-shadow: var(--boxshadowcards);

      img {
        width: 100%;
      }

      p {
        text-align: center;
        font-size: 1.5rem;
        background-color: var(--background1);
        color: var(--foreground1);
        padding: 5px;
      }
    }
  }
}

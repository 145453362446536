@import 'master';

favorite-btn {
  &.small {
    font-size: 14px;
  }

  &.medium {
    font-size: 16px;
  }

  &.large {
    font-size: 18px;
  }

  icon-button {
    &.icon-only button {
      background-color: transparent !important;
      box-shadow: none;

      icon {
        width: 1.5rem;
        height: 1.5rem;

        svg {
          fill: var(--favoritesinactive);
          stroke: var(--favorites-border-inactive);
        }
      }
    }

    button {
      background-color: var(--favoritesinactive);
      border: none;
      icon svg {
        fill: var(--favoritesforeground);
      }

      &:active:not([disabled]) {
        background-color: var(--favoritesinactive);
      }
    }
  }

  .active {
    &.icon-only {
      button icon svg {
        fill: var(--favoritesactive);
        stroke: var(--favorites-border-active);
      }
    }

    button {
      background-color: var(--favoritesactive);

      &:active:not([disabled]) {
        background-color: var(--favoritesactive);
      }
    }
  }
}

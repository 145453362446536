@import 'master';

.specs-container {
  width: 100%;
  background-color: var(--background6);
  color: var(--foreground6);
  position: relative;

  .specs-header {
    width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    & > icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;

      svg {
        fill: var(--background5);
      }
    }

    h2 {
      flex-grow: 1;
      font-size: 16px;
    }

    .arrow {
      width: 14px;
      height: 14px;
      margin-left: auto;

      icon {
        transform: rotate(0);
        transition: transform 150ms ease-in;

        svg {
          fill: var(--foreground6);
        }
      }
    }
  }

  &.open {
    & > .specs-header {
      .arrow {
        icon {
          transform: rotate(90deg);
        }
      }
    }
    & > .expand {
      border-top: 1px dashed var(--foreground2-alpha-low);
      padding: 1rem;
      margin-top: -0.5rem;
      max-height: 25vh;
    }
    & > .specs-body {
      visibility: visible;
      opacity: 1;
    }
  }
  .specs-body {
    background-color: var(--background6);
    color: var(--foreground6);
    overflow-x: visible;
    border-radius: var(--border-radius-cards);
    width: 100%;
    opacity: 0;
    transition: visibility 200ms, opacity 200ms ease-in;
    visibility: hidden;

    icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.65rem;
      flex-shrink: 0;

      svg {
        fill: var(--background4);
      }
    }

    .price {
      border-bottom: 1px dashed var(--foreground2-alpha-low);
      padding: 0.5rem 0 0.75rem 0;
      display: flex;
      align-items: center;
    }

    .tags {
      border-top: 1px dashed var(--foreground2-alpha-low);
      padding: 0.75rem 0 0.5rem 0;
      display: flex;
      align-items: flex-start;

      icon {
        margin-top: 0.2rem;
      }
    }

    .details-with-price {
      padding: 0.7rem 0 0.5rem 0;
    }

    .details-without-price {
      padding: 0.5rem 0;
    }

    .floorplan-details {
      display: flex;
      flex-wrap: wrap;

      .detail {
        width: 50%;
        padding: 0.3rem 0;
        display: flex;
        align-items: center;

        @include media('<tablet') {
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }

  .expand {
    position: absolute;
    z-index: 3;
    box-shadow: var(--box-shadow-inputs);
  }
}

.open-enabled {
  box-shadow: var(--box-shadow-inputs);
  border-radius: var(--border-radius-cards);

  & > .specs-body {
    @include hoverVisibilityScrollbarOverflowY;
  }
}

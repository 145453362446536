tag-filter {
  display: block;

  header {
    toggle {
      padding-right: 0.5rem;
    }
  }

  .badges {
    padding: 1rem;
    border-top: 1px solid var(--foreground2-alpha-low);

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    button {
      margin: 3px 10px 3px 0;
    }
  }
}

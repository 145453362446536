@import 'master';

.toast-container {
  @include fixed(top 0 bottom 0 left 0 right 0);
  z-index: 6; // above the modal component
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-wrap: wrap;

  --background: var(--modal-background-alpha-high, rgba(0, 0, 0, 0.7));
  --foreground: var(--modal-foreground);
  --icon-foreground: var(--modal-icon-foreground-alpha-medium, #fff);

  .success {
    --background: var(--modal-success-background-alpha-high, --modal-success-background);
    --foreground: var(--modal-success-foreground);
    --icon-foreground: var(
      --modal-success-icon-foreground-alpha-medium,
      --modal-success-icon-foreground
    );
  }

  .error {
    --background: var(--modal-alert-background-alpha-high);
    --foreground: var(--modal-alert-foreground);
    --icon-foreground: var(--modal-alert-icon-foreground-alpha-medium);
  }

  .toast-modal {
    background-color: var(--background, red);
    display: flex;
    min-width: 200px;
    max-width: 50vw;
    max-height: 95vh;
    overflow-y: auto;
    border-radius: var(--border-radius-modals);
    color: var(--foreground);
    padding: 58px 52px 54px 42px; //matches modal
    text-align: center;
    position: relative;

    &#loading-indicator {
      width: 200px;
      height: 200px;
      padding: 3rem;

      .spin-stretch {
        width: 100%;
        height: 100%;
        border-top-color: var(--foreground);
        border-bottom-color: var(--foreground);
      }
    }

    &.align-low {
      bottom: -20%;
    }

    .close-icon {
      @include absolute(top 0 right 0);
      width: 1rem;
      height: 1rem;
      margin: 1.5rem 1.25rem;
      cursor: pointer;

      svg {
        fill: var(--foreground);
      }

      &:focus {
        outline: 2px solid black;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
    }

    .icon {
      height: 2.6rem;
      @include flex-center-center();

      icon {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 10px;

        svg {
          fill: var(--icon-foreground);
        }
      }
    }

    .content {
      text-align: left;

      .title {
        font-size: 1.75rem;
      }

      .message {
        padding-top: 10px;
        white-space: pre-wrap;
        max-height: 70vh;
        overflow-y: auto;
        padding-right: 5px; // extra padding between the text and the scrollbar

        & > * + * {
          margin-top: 1rem;
        }
      }

      .confirm-buttons {
        display: flex;
        align-items: center;
        padding-top: 1.5rem;

        icon-button:first-child {
          margin-right: 1.5rem;

          icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    @include media('<desktop') {
      min-width: unset;
      max-width: none;
      width: 100vw;
      padding: 38px 32px 34px 22px; // matches modal

      .content {
        width: 100%;

        .message {
          max-height: 40vh;
        }

        .confirm-buttons {
          padding-top: 0;
          padding-bottom: 1rem;
          flex-wrap: wrap;

          icon-button {
            padding-top: 1rem;

            button .text {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}

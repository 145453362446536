lot-zoom-in {
  display: block;

  .lot-preview {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    transform: translateZ(0);

    svg {
      width: 100%;
      height: 100%;
      pointer-events: none;

      #houses_and_legend {
        pointer-events: none;
      }

      #Lots > g,
      [id^='Lots_'] > g {
        cursor: auto;

        [id$='secondarysymbol'],
        [id*='-dims'],
        [id*='_dims'] {
          display: none;
        }
      }
    }
  }

  .neighborhood-svg-container {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
}

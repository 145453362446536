@import 'master';

favorites-counter {
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;

  &.has-count {
    margin-right: 25px;

    .counter {
      display: flex;
    }
    .icon {
      background-color: var(--favoritesactive);
    }
  }

  &.icon-only {
    .icon {
      background-color: unset;

      icon {
        width: 100%;
        height: 100%;

        svg {
          fill: var(--favoritesinactive);
          stroke: var(--favorites-border-inactive);
        }
      }
    }

    .counter {
      left: 100%;
    }

    &.has-count {
      .icon svg {
        fill: var(--favoritesactive);
        stroke: var(--favorites-border-active);
      }
    }
  }

  .icon {
    position: relative;
    width: 100%;
    height: 100%;
    @include flex-center-center();
    background-color: var(--favoritesinactive);
    border-radius: 50%;

    icon {
      width: 50%;
      height: 50%;

      svg {
        fill: var(--favoritesforeground);
      }
    }
  }

  .counter {
    display: none;
    position: absolute;
    top: 0;
    left: 80%;
    background-color: var(--favorites-counter-circle-background);
    color: var(--favorites-counter-circle-foreground);
    width: 100%;
    height: 100%;
  }
}

@import 'master';

accordion {
  display: block;
  position: relative; // Added because Safari!

  .accordion-container {
    width: 100%;
    background-color: var(--background6);
    color: var(--foreground6);
    box-shadow: var(--boxshadowaccordion);
    border-radius: var(--border-radius-accordions);
    overflow: hidden;

    &.open {
      & > .accordion-body {
        border-top: 1px solid var(--foreground2-alpha-low);
      }
    }

    &.no-collapse {
      box-shadow: var(--boxshadowcards);
      favorite-btn {
        margin-right: 0;
      }
    }

    .accordion-header {
      width: 100%;
      padding: 0.75rem 1rem;
      display: flex;
      align-items: center;
      min-height: 50px;
      cursor: default;

      &.left-expander {
        flex-direction: row-reverse;
      }

      .toggler {
        width: 16px;
        height: 16px;
        margin-left: auto;

        icon {
          animation: fadeIn 150ms ease-out;

          svg {
            fill: var(--foreground6);
          }
        }
      }

      div[header] {
        display: flex;
        align-items: center;
        flex-grow: 1;

        & > icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          flex-shrink: 0;

          svg {
            fill: var(--foreground6);
          }
        }

        h2 {
          flex-grow: 1;
          font-size: 16px;
          font-weight: var(--accordionheaderfontweight);
        }
      }
    }

    .accordion-body {
      width: 100%;

      &.is-hidden {
        display: none;
      }
    }

    .body-content {
      width: 100%;
      padding: 1rem;

      h4 {
        font-size: 12px;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
    }

    .accordion-list-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--foreground2-alpha-low);
      padding: 0.5rem 0;
      font-size: 1em;

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        position: relative;
        background-color: var(--global-active-background-alpha-low);

        &::before {
          content: ' ';
          width: 0.5rem;
          @include absolute(top 0 bottom 0 left 0);
          background-color: var(--global-active-background);
          animation: fadeIn 175ms ease-in;
        }
      }

      .item {
        transition: background-color 150ms linear;

        &:hover {
          background-color: var(--background4-alpha-low);
        }
      }

      .decoration,
      favorite-btn {
        flex: 0 0 auto;
      }

      .decoration {
        width: 1.3em;
        height: 1.3em;
        margin-right: 12px;
        margin-left: 4px;

        svg {
          fill: var(--foreground6);
        }

        &.favorited svg {
          fill: var(--favoritesactive);
        }
      }

      .text {
        flex: 1 1 auto;

        h3 {
          font-size: 0.9em;
        }
        p {
          font-size: 0.7em;
        }
      }

      favorite-btn {
        justify-self: flex-end;
      }
    }

    // Different styles for child accordions
    .accordion-container {
      box-shadow: none;
      .accordion-header {
        padding: 0.5rem 0;
        border-bottom: 1px dashed var(--foreground2-alpha-low);
      }
      &.open > .accordion-body {
        border-top: none;
      }
    }
  }

  &.sticky-header {
    .accordion-container {
      overflow: visible;

      .accordion-header {
        position: sticky;
        top: -1rem;
        background-color: var(--background6);
      }

      &.open {
        & > .accordion-header {
          border-bottom: 1px dotted var(--background5);
        }

        & > .accordion-body {
          border-top: none;
        }
      }
    }
  }
}

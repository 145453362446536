@import 'master';

video-with-preview {
  display: block;
  width: 100%;
  height: 100%;
  background-color: gray;
  position: relative;

  img,
  video {
    width: 100%;
    height: 100%;
  }

  .preview {
    @include absolute(top 0 right 0 bottom 0 left 0);
    z-index: 2;
    object-fit: cover !important;
  }

  img,
  button {
    opacity: 1;
    transition: opacity 200ms ease;
  }

  .hide {
    opacity: 0;
    pointer-events: none;
  }

  .no-display {
    display: none;
  }

  icon-button {
    @include align-both-directions();
    z-index: 3;
    padding: 1rem;

    button icon {
      margin: 0;
      width: 2rem;
      height: 2rem;
    }
  }
}

@import 'master';

positionable-popup {
  $background: var(--background1-alpha-high);
  $foreground: var(--foreground1);

  .popup-container {
    position: absolute;
    background-color: $background;
    color: $foreground;
    padding: 1rem;
    border-radius: var(--border-radius-modals);
    z-index: 5;
    transition: right 300ms ease; // matches slide out menu

    .close-icon {
      @include absolute(top 0 right 0);
      width: 1rem;
      height: 1rem;
      margin: 1rem;
      cursor: pointer;

      svg {
        fill: $foreground;
      }
    }
  }
}

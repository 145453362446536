toggle-with-label {
  display: flex;
  align-items: center;

  icon {
    height: 1rem;
    width: 1rem;
    flex-shrink: 0;

    &.active {
      svg {
        fill: var(--global-active-background);
      }
    }

    &.inactive {
      svg {
        fill: var(--background5);
      }
    }
  }

  label {
    font-weight: bold;
  }

  label,
  toggle,
  input {
    margin-left: 0.5rem;
  }

  input {
    height: 1.5rem;
    width: 1.5rem;
  }

  .custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 1.5rem;
  }
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: var(--background2);
    border: 1px solid var(--background5);
    border-radius: 3px;
  }
  .custom-checkbox input:checked ~ .checkmark {
    background-color: var(--global-active-background);
  }
  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }
  .custom-checkbox input:checked ~ .checkmark::after {
    display: block;
  }
  .custom-checkbox .checkmark::after {
    left: 7px;
    top: 1px;
    height: 15px;
    width: 9px;
    border: solid var(--global-active-foreground);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}

hc-session-change-dialog {
  display: block;
  text-align: center;

  & > * + * {
    margin-top: 1rem;
  }

  button {
    margin: 0.5rem;
  }

  .selectable {
    color: var(--hc-accent1);
    text-decoration: underline;
    display: inline-block;
  }
}

.session-change-dialog-panel .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: var(--hc-background1);
  color: var(--hc-foreground1);
  padding: 1rem;
}

@import '../../../../styles/master';

hc-mortgage-calculator {
  display: block;
  padding: 1rem;
}

.mortgage-calculator-modal {
  .close {
    position: absolute;
    right: 25px;
    top: 25px;

    icon {
      width: 20px;
      height: 20px;
      fill: var(--hc-accent6);
    }
    &:hover {
      cursor: pointer;
    }
  }

  @include media('<tablet') {
    .calculator-container {
      font-size: 12px;
    }

    .close {
      right: 10px;
    }
  }

  .calculator-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    header {
      margin: 10px;
      h2 {
        font-weight: normal;
      }
    }

    .calc-form {
      width: 96%;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      padding-bottom: 0.5rem;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .loan-type-check-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .loan-type-check {
          display: flex;
          justify-content: flex-end;
          padding: 5px 0;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
          }
          label {
            margin-left: 10px;
            color: var(--hc-accent6);
            &:hover {
              cursor: pointer;
            }
          }
          .check {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 1rem;
            margin-left: 15px;
            flex-shrink: 0;
            background-color: var(--hc-negative-button-background);
            icon {
              width: 45%;
              fill: var(--hc-success-foreground);
            }
          }
          &:first-child {
            border-right: 1px solid black;
            padding-right: 10px;
            label {
              margin-left: 0;
            }
          }
          &.enabled {
            label {
              color: black;
            }
            .check {
              background-color: var(--hc-success-background);
            }
          }
        }
      }

      .input-container {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          border: 1px solid black;
          border-radius: 2rem;
          width: 94%;
          margin-left: 5px;
          text-align: right;
          padding-right: 12px;
          &:disabled {
            background-color: var(--hc-accent7);
          }
        }

        .small {
          flex: 1 1 0;
          display: flex;
          align-items: center;

          & + .small {
            margin-left: 1em;
          }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          appearance: none;
        }
      }

      &.center {
        justify-content: center;
        align-items: center;
      }

      @include media('<tablet') {
        flex-direction: column;
        align-items: flex-start;

        .loan-type-check-container,
        .input-container {
          width: 100%;
          justify-content: flex-start;
        }

        label {
          margin-bottom: 0.5em;
        }
      }
    }

    .result-container {
      width: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;

      p {
        font-size: 1.5rem;
        color: var(--hc-accent1);
      }
    }
  }
}

.mortgage-calculator-modal-backdrop {
  background-color: var(--modal-backdrop);
}

.mortgage-calculator-modal {
  @include hoverVisibilityScrollbarOverflowY();
}

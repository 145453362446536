@import 'master';

watermark-banner {
  position: absolute;
  z-index: 1;

  & > div {
    @include flex-center-center();
    background-color: red;
    color: white;
    opacity: 0.5;
    width: 100vw;
    height: 11vh;

    h2 {
      font-family: var(--headerfont);
      text-transform: var(--headerfontuppercase);
      font-weight: lighter;
    }
  }
  .blink-animation {
    animation: blink 4s linear infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0.2;
    }
  }
}

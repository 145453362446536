@import '../../../styles/master';

hc-fp-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  & > header {
    display: flex;
    align-items: center;
    flex: 0 0 0;
    padding: 2rem;
    line-height: 1;
    background-color: var(--hc-background1);
    color: var(--hc-foreground1);
    box-shadow: var(--boxshadowaccordion);

    @include media('<tablet') {
      padding: 1rem 2rem;
    }

    .title-container {
      display: flex;

      @include media('<tablet') {
        flex-direction: column;
      }

      h1 {
        font-size: 1.35rem;

        @include media('<tablet') {
          font-size: 1rem;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: normal;
        margin-left: 1rem;
        padding-left: 1rem;
        border-left: 1px solid var(--hc-accent2);
        display: flex;
        align-items: center;

        @include media('<tablet') {
          margin: 0;
          padding: 0.65rem 0 0 0;
          border: none;
        }

        icon {
          width: 1em;
          height: 1em;
          fill: var(--hc-accent1);
          margin-right: 0.5em;
        }
      }
    }

    .close-btn {
      width: 1em;
      height: 1em;
      margin-left: auto;

      icon {
        width: 100%;
        height: 100%;
      }
    }

    @include media('<tablet') {
      h1 {
        font-size: 16px;
      }
    }
  }

  .cards-container {
    flex: 1 1 auto;
    min-height: 0;
    display: block;
    @include hoverVisibilityScrollbarOverflowY();
    position: relative;
    padding: 1rem;

    p.label {
      font-size: 14px;
      font-weight: 600;
      margin: 0.5rem 1rem;
    }

    mat-select {
      display: block;
      width: 25%;
      min-width: 125px;
      border-radius: 20px;
      padding: 0.5rem 1.5rem;
      margin: 0 1rem 1rem;
    }

    .col {
      display: inline-block;
      vertical-align: top;
      width: 33%;
      padding: 1rem;

      hc-fp-card {
        width: 100%;
      }
    }
  }

  @include media('<desktop', 'portrait') {
    .cards-container {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      @include hoverVisibilityScrollbarOverflowY();

      .col {
        width: 100%;
      }
    }
  }

  @include media('<desktop', 'landscape') {
    .cards-container .col {
      width: 50%;
    }
  }
}

@import '../../../../styles/master';

hc-fp-card {
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  background-color: var(--hc-background1);
  color: var(--hc-foreground1);
  box-shadow: var(--boxshadowcards);
  border-radius: 8px;
  white-space: normal;

  .card-header {
    grid-row: 1 /2;
    width: 100%;
    padding: 0.75rem;
    display: flex;
    align-items: center;

    hc-ih-badge {
      margin-right: 0.5rem;
    }

    .index {
      white-space: nowrap;
    }

    p {
      font-style: italic;
      font-size: 12px;
    }
  }

  .card-body {
    grid-row: 2 / 3;
    width: 100%;

    gallery {
      height: auto;
      aspect-ratio: 16 / 9;
    }

    .container {
      padding: 1.25rem;
      width: 100%;

      @include media('<tablet') {
        font-size: 14px;
      }
    }

    floorplan-specs {
      .specs-container,
      .specs-body {
        background-color: transparent;
      }

      .specs-container .specs-body {
        color: var(--hc-foreground1);

        .floorplan-details {
          padding: 0.75rem 0 0;
        }

        .price {
          padding-bottom: 0;

          .value-display {
            font-weight: bold;
          }
        }
        icon svg {
          fill: var(--hc-accent1);
        }
      }
    }
  }

  .fp-select-btn {
    margin-top: 1rem;
    width: 100%;
  }
}

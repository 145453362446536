@import 'master';

#position-control {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .position-control-middle {
    display: flex;

    .position-control-spacer {
      width: 30px;
      height: 30px;
      background-color: var(--background6-alpha-high);
    }
  }

  button {
    border: 1px solid var(--background5);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background6-alpha-high);
    padding-bottom: 2px;

    .arrow {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 15px solid var(--foreground6);
    }
  }

  #move-left {
    transform: rotate(270deg);
  }

  #move-right {
    transform: rotate(90deg);
  }

  #move-down {
    transform: rotate(180deg);
  }

  #map-moved-message {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -100;
  }
}

@import '../../../../styles/master';

hc-saved-builds {
  display: block;
  background-color: var(--hc-background1);
  color: var(--hc-foreground1);

  & > header {
    width: 100%;
    padding: 1rem 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--hc-background1-alpha-high);
    position: sticky;
    box-shadow: var(--box-shadow);
    top: 0;
    z-index: 4;

    @include media('<tablet') {
      padding: 1rem 1.25rem;
    }

    img {
      height: 4rem;
      max-width: 250px;
      margin-right: 1rem;
      object-fit: contain;
      object-position: center;
    }

    @include media('<desktop') {

      img {
        max-width: 80px;
        height: 2rem;
      }

    }

    b {
      color: var(--hc-accent1);
    }

    .close-btn {
      width: 1em;
      height: 1em;
      margin-left: auto;

      icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  .message-section {
    background-color: var(--hc-background2);
    color: var(--hc-foreground2);
    padding: 1rem;
    margin: 1rem 2rem 0;
    text-align: center;

    @include media('<tablet'){
      margin: 1rem 1rem 0;
    }

    a {
      font-weight: bold;
      color: var(--hc-accent1);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .cards-section {
    h1 {
      color: var(--hc-accent1);
      font-weight: bold;
    }

    .results-count {
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 1px solid var(--hc-foreground1);
      font-weight: bold;
    }

    .new-build-btn {
      margin-left: auto;
    }

    @include media('<tablet') {
      .results-count {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        width: 100%;
      }
      .new-build-btn {
        width: 100%;
        margin-top: 1rem;
      }
    }

    .cards-header {
      padding: 2.65rem 2rem 1.65rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @include media('<tablet') {
        padding: 2rem 1.25rem;
      }
    }

    .cards-container {
      padding: 0 1rem;

      @include media('<tablet') {
        padding: 0;
      }

      .col {
        display: inline-block;
        vertical-align: top;
        width: 33%;
        padding: 1rem;

        @include media('<1366px') {
          width: 50%;
        }
        @include media('<tablet') {
          width: 100%;
          padding: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@import 'master';

.help {
  @include fixed(top 0 left 0 right 0 bottom 0);
  z-index: 10;
  background-color: black;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .close {
    @include absolute(top 10px right 10px);
    z-index: 10;
  }
}

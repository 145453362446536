@import 'master';

share-dialog {
  .share-form {
    display: flex;
    flex-direction: column;

    i {
      font-size: 0.8rem;
    }

    icon {
      width: 1rem;
      height: 1rem;
    }

    .row {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      input,
      textarea {
        margin: 0.5rem 0;
      }
    }

    icon-button {
      margin-left: auto;
    }
  }
}

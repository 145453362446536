@import 'master';

#incompatible-browser {
  @include fixed(top 0 left 0 right 0 bottom 0);
  z-index: 9;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 20px;
}

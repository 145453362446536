@import 'master';

home-configurator-root #fullscreen-gallery {
  // need to beat Anuglar Material dialog in z-index
  // TODO: find better way than this??
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.86);
}

#fullscreen-gallery {
  @include fixed(top 0 left 0);
  width: 100%;
  height: 100%;
  z-index: 7;
  background-color: var(--background1-alpha-high);

  header {
    @include absolute(top 0 right 0);
    z-index: 3;
    display: flex;
    background-color: var(--gallery-ctrl-fullscreen-bkg);
    color: var(--foreground1);

    $headerHeight: 3rem;

    @include media('<desktop') {
      left: 0;
      width: 100%;
      justify-content: flex-end;

      h3 {
        white-space: nowrap;
      }
    }

    h3 {
      font-weight: normal;
      margin: 0 1.85rem;
      font-size: 1.5rem;
      align-self: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    favorite-btn {
      @include flex-center-center();
      margin-left: 1rem;
    }

    .close-btn {
      width: 1rem;
      height: 1rem;
      margin: 1rem;
      cursor: pointer;

      svg {
        fill: var(--foreground4);
      }
    }

    .pager {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      padding: 0.5rem 1rem;
      border-right: 1px solid rgba(255, 255, 255, 0.15);
      border-left: 1px solid rgba(255, 255, 255, 0.15);

      p {
        margin: 0 0.5rem;
      }

      button {
        width: $headerHeight - 1rem;
        height: $headerHeight - 1rem;
        padding: 0.5rem;
      }

      .left-arrow {
        transform: rotate(180deg);
      }

      .left-arrow,
      .right-arrow {
        button {
          background-color: transparent;
          box-shadow: none;
          border: none;
          svg {
            fill: var(--gallery-button-foreground);
          }
        }
      }
      .btn-background {
        & > button {
          background-color: var(--gallery-button-bkg);
          box-shadow: var(--boxshadowbuttons);
        }
      }
    }
  }

  .loader {
    @include absolute(top 0 right 0 bottom 0 left 0);
    z-index: 2;
    background-color: var(--background2-alpha-medium);
    @include flex-center-center();
  }

  img,
  video,
  iframe {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }

  .fullscreen {
    object-fit: cover;
  }

  .img-placeholder {
    height: 100%;
    background-color: var(--background5);
    @include animation-fadeIn();
  }

  .swipe-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }
  .swipe-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    white-space: nowrap;

    &.is-animating {
      transition: transform 300ms cubic-bezier(0.5, 0, 0.5, 1);
    }
  }

  .image-slide {
    display: inline-block;
    height: 100%;
    width: 100%;

    .html-container,
    .transform-receptor,
    .svg-container {
      width: 100%;
      height: 100%;
    }

    .svg-container svg {
      width: 100%;
      height: 97%;
    }
  }
}

@import 'master';

.hide-hotspots hotspot {
  display: none;
  visibility: hidden;
}

hotspot {
  @include absolute(top 0 left 0);

  .hotspot-circle {
    background-color: var(--background7);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.pulse {
      animation: pulse 1000ms;
    }

    icon {
      width: 25px;
      height: 25px;

      & > svg {
        fill: var(--foreground7);
        position: relative;
      }
    }
  }

  .radar {
    @include absolute(top -20px left 0);
    height: 40px;
    width: 40px;
    background-image: linear-gradient(0deg, var(--background7), transparent);
    background-position: center center;
    clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
    transform-origin: bottom center;
  }
}

@import '../../../styles/master';

sitemap-viewer {
  display: flex;
  flex-direction: column;

  hr {
    width: 100%;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .svg-viewport {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 85%;

    .svg-container {
      @include absolute(top 0 left 0);
      transform-origin: 0 0;

      &.has-printbox {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    margin: 1rem 0.5rem;

    .swatch {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    .counter {
      margin-left: 0.5rem;
    }
  }
}

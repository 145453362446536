.hc-share-dialog-panel .mat-mdc-dialog-surface {
  padding: 1rem;
}

hc-share-dialog {
  .share-form {
    display: flex;
    flex-direction: column;

    i {
      font-size: 0.8rem;
    }

    icon {
      width: 1rem;
      height: 1rem;
    }

    .row {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      input,
      textarea {
        margin: 0.5rem 0;
        border-radius: 8px;
      }
    }

    button {
      margin-left: auto;
    }
  }
}

@import 'master';
navigational-sections-content {
  .row-item-container {
    border-radius: var(--border-radius-accordions);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--background6);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);

    & + .row-item-container {
      margin-top: 1rem;
    }
  }
  .row-content-container {
    display: flex;
    align-items: center;

    & > icon {
      width: 30px;
      height: 30px;
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    & icon-button > button {
      height: 2rem;
    }

    &:first-child {
      margin-left: 0.5rem;
    }
    &:last-child {
      margin-right: 0.5rem;
    }

    h3 {
      font-size: 1rem;
    }
  }
  .fav-btn {
    margin-left: 1rem;
  }

  .pdf-viewer {
    .loader {
      @include absolute(top 0 right 0 bottom 0 left 0);
      z-index: 7;
      background-color: var(--background2-alpha-medium);
      @include flex-center-center();
    }
    .modal-backdrop {
      @include fixed(top 0 left 0 right 0 bottom 0);
      z-index: 6;
      @include flex-center-center();

      --modal-height: 95vh;
      --modal-width: 66vw;
      .modal {
        background-color: var(--modal-background-alpha-high);
        color: var(--modal-foreground);
        max-width: var(--modal-width);
        position: relative;
        border-radius: var(--border-radius-modals);
        max-height: var(--modal-height);
        @include hoverVisibilityScrollbarOverflowY;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media('<desktop') {
          min-width: unset;
          max-width: none;
          width: 100vw;
        }

        .modal-header {
          width: 100%;
          max-height: 10%;
          border-bottom: 1px solid var(--modal-foreground);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;

          @include media('<desktop') {
            padding: 10px;
          }

          .title-container {
            display: flex;
            padding-top: 0.5rem;
            padding-left: 0.5rem;

            & > icon {
              width: 30px;
              height: 30px;
              margin-right: 0.5rem;
              flex-shrink: 0;
            }
          }

          .btn-container {
            display: flex;
            padding-top: 0.5rem;
            padding-right: 0.5rem;

            .close-icon {
              svg {
                fill: var(--modal-foreground);
              }
            }
          }
        }

        .modal-content {
          padding: 50px;

          &.is-hidden {
            min-width: var(--modal-width);
            height: var(--modal-height);
            position: relative;

            @include media('<desktop') {
              min-width: unset;
              max-width: none;
              width: 100vw;
            }

            .pdf-container {
              display: none;
            }
          }

          .pdf-container,
          .loader {
            height: 100%;
            width: 100%;
            @include flex-center-center();
          }

          @include media('<desktop') {
            padding-bottom: 22px;
            padding-top: 22px;
          }

          img {
            object-fit: contain;
          }

          iframe {
            width: 55vw;
            height: 70vh;
            border: none;
          }
        }

        icon {
          svg {
            fill: var(--modal-icon-foreground-alpha-medium);
          }
        }
      }
    }
  }
}
